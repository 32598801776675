import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import LogoUrl from '../../res/parkage-logo.png';

import Notify from '../../components/base/Notify';
import Ajax from '../../utils/Ajax';
import Config from '../../config';

import styles from './styles.js';

const errorMessages = {
  notFound: 'Ce login n\'existe pas',
  passMismatch: 'Mot de passe incorrect',
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      loadingLogin: false,
      renderMissing: false,
      error: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.passwordInputRef = React.createRef();
  }

  /**
   * HANDLERS
   */
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value, renderMissing: false });
  }

  async handleLogin() {
    const { onUser } = this.props;
    const { login, password } = this.state;
    if (!login || !password) {
      return this.setState({ renderMissing: true });
    }
    this.setState({ loadingLogin: true });
    const res = await Ajax.get(
      {
        url: 'api/token',
        queryString: {
          login,
          password,
        },
        token: false,
      }
    );
    if (res.type === 'error') {
      Notify.error(errorMessages[res.error] || res.message);
      return this.setState({ loadingLogin: false, error: res.error });
    }
    window.sessionStorage.setItem(Config.TOKEN_KEY, res.token);
    onUser(res.user);
  }

  handleKeyUp(evt) {
    if (evt.key === "Enter") {
      if(evt.target.name === "login") {
        this.passwordInputRef.current.focus();
      } 
      else {
        this.handleLogin();
      }
    }
  }

  /**
   * RENDER
   */
  render() {
    const { classes } = this.props;
    const { login, password, loadingLogin, renderMissing, error } = this.state;
    return (
      <Box className={classes.wrapper}>
        <div className={classes.gridWrapper} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={2} sx={{alignItems: {xs:'center', sm:'center',md:'flex-start'}}} >
                <img className={classes.logo} src={LogoUrl} alt="Parkage" />
                <Typography variant="h3" sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>Module de gestion</Typography>
                <Typography variant="h3" sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>des APIs de marketplaces</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Paper className={classes.content} elevation={2}>
                <form id="Login-form" onKeyUp={this.handleKeyUp}>
                  <Stack spacing={2}>
                    <TextField
                      required
                      name="login"
                      value={login}
                      label="Login"
                      autoComplete='login'
                      error={(renderMissing && !login) || error === 'notFound'}
                      disabled={loadingLogin}
                      onChange={this.handleChange}
                    />
                    <TextField
                      required
                      inputRef={this.passwordInputRef}
                      name="password"
                      value={password}
                      label="Mot de passe"
                      type="password"
                      autoComplete='password'
                      error={(renderMissing && !password) || error === 'passMismatch'}
                      disabled={loadingLogin}
                      onChange={this.handleChange}
                    />               
                    <Button variant="contained" onClick={this.handleLogin} disabled={loadingLogin} fullWidth>Connexion</Button>
                    {loadingLogin && <LinearProgress color="secondary" />}
                  </Stack>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Box>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  user: PropTypes.object,
  onUser: PropTypes.func.isRequired,
}
export default withStyles(styles)(Login);
